import React, { Component } from "react"
import Header from "./header.jsx"
import TypingCodeEffect from "./components/TypingCodeEffect.jsx"

class Code extends Component {
    render() {
        const contactForm = (
            <div>
                <Header />
                <div className="page-body product code">
                    <div className="title-text">
                        <TypingCodeEffect />
                    </div>
                    <div className="description-text">
                        More Coming Soon...
                    </div>
                </div>
            </div>
        )
        return contactForm
    }
}

export default Code