import React, { Component, useState, useRef } from "react"
import { Link } from "react-router-dom"
import Gallery from "./gallery.jsx"

import Header from "./header.jsx"
import Hero from "./hero.jsx"
import HeroAnimation from "./hero-animation.jsx"
import HeroVideoPlayer from "./components/hero-video-player.jsx"

// Logo animation
// import { Player, Controls } from '@lottiefiles/react-lottie-player'
// import '@dotlottie/player-component';
// import { DotLottiePlayer, Controls } from '@dotlottie/react-player'
// import '@dotlottie/react-player/dist/index.css'; 
// import { DotLottieReact } from '@lottiefiles/dotlottie-react';
// import logoAnimationData from "./lottie/Variable Studio - Logo - Lottie - 07.lottie"
// import logoAnimationData from "./lottie/Variable Studio - Logo - Lottie - 06.json"
import HeroAnimation from "./hero-animation.jsx"
import HeroVideoPlayer from "./components/hero-video-player.jsx"

// Client Logos
// const iveyMediaLogo = new URL("./img/client-logos/iveyMedia-white.png", import.meta.url)
const withButler = new URL("./img/client-logos/withButler.png", import.meta.url)
const forTheCity = new URL("./img/client-logos/forTheCityASCC-white.png", import.meta.url)
const fsts = new URL("./img/client-logos/FSTS.png", import.meta.url)
const redeemerMidland = new URL("./img/client-logos/Redeemer Midland Logo.png", import.meta.url)
const storyMinute = new URL("./img/client-logos/Story Minute Logo.png", import.meta.url)
const austinStone = new URL("./img/client-logos/the-austin-stone-logo-white-horizontal.png", import.meta.url)
const asw = new URL("./img/client-logos/WORSHIP_horizontal_gray.png", import.meta.url)
const weatherAndStory = new URL("./img/client-logos/Weather and Story Logo.png", import.meta.url)
const flo = new URL("./img/client-logos/FloSports-Secondary-igniteblack.png", import.meta.url)
const prestonAudio = new URL("./img/client-logos/prestonAudio-white.png", import.meta.url)
const rev = new URL("./img/client-logos/Rev_Black.png", import.meta.url)
const tgc = new URL("./img/client-logos/tgc_logo.png", import.meta.url)


class Home extends Component {
    constructor(props) {
        super(props);
        this.animation = React.createRef()
    }

    componentDidMount() {
        // setTimeout(() => {
        //     this.animation.current.play()
        // }, 500)
    }

    render() {
        const home = (
            <div>
                <Header />
                {/* <Hero /> */}
                <HeroVideoPlayer />
                <HeroAnimation />
                {/* <div className="logo-animation"> */}
                    {/* <iframe src="https://lottie.host/embed/7af6b564-186e-4978-abad-75a448193170/tzakwAukxY.lottie"></iframe> */}
                    {/* <Player
                        // onEvent={event => {
                        //     if (event == 'load') this.animation.current.play()
                        // }}
                        ref={this.animation} // set the ref to your class instance
                        autoplay={true}
                        loop={false}
                        src={logoAnimationData}
                        keepLastFrame={true}
                        renderer={"svg"}
                        >
                        <Controls visible={false} />
                    </Player> */}
                    {/* <DotLottiePlayer
                        ref={this.animation} // set the ref to your class instance
                        autoplay={false}
                        loop={false}
                        src={logoAnimationData}
                        keepLastFrame={true}
                        renderer={"svg"}
                        >
                        <Controls visible={false} />
                    </DotLottiePlayer> */}
                {/* </div> */}
                <div className="subtitle">
                    <Link to="/film">
                        <h2>Film</h2>
                    </Link>
                    <h2>|</h2>
                    <Link to="/animation">
                        <h2>Animation</h2>
                    </Link>
                    <h2>|</h2>
                    <Link to="/code">
                        <h2>Code</h2>
                    </Link>
                    <h2>|</h2>
                    <Link to="/motion-templates">
                        <h2>Motion Graphic Templates</h2>
                    </Link>
                </div>
                <div className="home-client-logos">
                    <a href="https://withbutler.com" target="_blank" rel="noopener noreferrer">
                        <img src={withButler} alt="With Butler"/>
                    </a>
                    <a href="https://www.austinstone.org/initiative/for-the-city" target="_blank" rel="noopener noreferrer">
                        <img className="invert" src={forTheCity} alt="For the City"/>
                    </a>
                    <a href="https://fromstudiotostage.com" target="_blank" rel="noopener noreferrer">
                        <img src={fsts} alt="From Studio to Stage"/>
                    </a>
                    <a href="https://www.flosports.tv" target="_blank" rel="noopener noreferrer">
                        <img src={flo} alt="Flo Sports"/>
                    </a>
                    <a href="https://www.story-minute.com" target="_blank" rel="noopener noreferrer">
                        <img src={storyMinute} alt="Story Minute"/>
                    </a>
                    <a href="https://www.austinstone.org" target="_blank" rel="noopener noreferrer">
                        <img src={austinStone} alt="Austin Stone"/>
                    </a>
                    <a href="https://weatherandstory.com" target="_blank" rel="noopener noreferrer">
                        <img src={weatherAndStory} alt="Weather & Story"/>
                    </a>
                    <a href="https://www.austinstone.org/initiative/austin-stone-worship" target="_blank" rel="noopener noreferrer">
                        <img src={asw} alt="Austin Stone Worship"/>
                    </a>
                    <a href="https://www.redeemermidland.org" target="_blank" rel="noopener noreferrer">
                        <img src={redeemerMidland} alt="Redeemer Midland"/>
                    </a>
                    <a href="https://www.prestonaudioatx.com" target="_blank" rel="noopener noreferrer">
                        <img className="invert" src={prestonAudio} alt="Preston Audio and Events"/>
                    </a>
                    <a href="https://www.rev.com" target="_blank" rel="noopener noreferrer">
                        <img src={rev} alt="Rev"/>
                    </a>
                    <a href="https://www.thegospelcoalition.org" target="_blank" rel="noopener noreferrer">
                        <img src={tgc} alt="The Gospel Coalition"/>
                    </a>
                </div>
                <div className="home-work">
                    <Gallery></Gallery>
                </div>
            </div>
        )
        // setTimeout(() => {
        //     this.animation.current.play()
        // }, 50)
        return home
    }

    
}

export default Home