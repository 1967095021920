import React, { Component } from "react"

import PlayPauseButton from "./play-pause-button.jsx"
import RivePlayPauseButton from "./rive-play-pause-btn.jsx"

const videoFile = new URL("../video/Reel 2023.mp4", import.meta.url)
const thumbnail = new URL("../video/Reel 2023_thumbnail.jpg", import.meta.url)

class HeroVideoPlayer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clipPath: window.innerWidth < 1200 ? 
            "inset(196px 40px 40px 40px round 40px)" 
            : "inset(96px 40px 40px 40px round 40px)", // Default clip path
            isPlaying: true, // Add state to manage play/pause
        }

        // Bind methods
        this.handleMouseOver = this.handleMouseOver.bind(this)
        this.handleMouseOut = this.handleMouseOut.bind(this)
        this.handlePlayPause = this.handlePlayPause.bind(this)

        // Create a ref to access the video element
        this.videoRef = React.createRef()
    }

    handleMouseOver() {
        // this.setState({ clipPath: "inset(0px 0px 0px 0px round 0px)" })
    }

    handleMouseOut() {
        // this.setState({ clipPath: window.innerWidth < 1200 
        //     ? "inset(196px 40px 40px 40px round 40px)" 
        //     : "inset(96px 40px 40px 40px round 40px)", 
        // })
    }

    // Play/Pause video
    handlePlayPause() {
        const video = this.videoRef.current; // Access video element via ref
        const { isPlaying } = this.state; // Destructure state

        if (isPlaying) {
            video.pause();
        } else {
            video.play();
        }

        // Toggle the state of isPlaying
        this.setState({ isPlaying: !isPlaying });
    }



    render() {
        const { clipPath, isPlaying } = this.state

        const heroVideoPlayer = (
            <div className="hero-video-blur">
                <div className="hero-video-player">
                    <video 
                        ref={this.videoRef}
                        width="1920px" 
                        height="1080px" 
                        className="video-bg"
                        id="vid" 
                        poster={thumbnail} 
                        muted 
                        autoPlay 
                        loop 
                        playsInline 
                        style={{clipPath: this.clipPath}} 
                        onMouseOver={this.handleMouseOver} 
                        onMouseOut={this.handleMouseOut}>
                            <source src={videoFile} type="video/mp4"></source>
                    </video>

                    {/* Play/Pause Button */}
                    {/* <PlayPauseButton isPlaying={isPlaying} handlePlayPause={this.handlePlayPause} /> */}
                    <RivePlayPauseButton isPlaying={isPlaying} handlePlayPause={this.handlePlayPause} />
                </div>
            </div>
            
        )
        return heroVideoPlayer
    }
}

export default HeroVideoPlayer