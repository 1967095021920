import React, { useState, useRef } from 'react';
import Rive from '@rive-app/react-canvas';

// Replace 'your-animation.riv' with the path to your Rive file
const riveFile = new URL('../rive/play-pause-button.riv', import.meta.url);

const RivePlayPauseButton = ({ isPlaying, handlePlayPause }) => {
    const riveRef = useRef();

  const handleClick = () => {
    handlePlayPause();
    // Set the state based on play/pause
    // const riveInstance = riveRef.current?.rive;
    // if (riveInstance) {
    //     const stateMachine = riveInstance?.stateMachines["State Machine 1"]
    //     if (stateMachine) {
    //         const input = stateMachine.inputs["Paused"];
    //         if (input) {
    //             input.value = !isPlaying;
    //         }
    //     }
    // }
  };

  return (
    <div className="rive-play-pause-btn">
      <Rive
        ref={riveRef}
        src={riveFile}
        stateMachines="State Machine 1"
        onClick={handleClick}
      />
    </div>
  );
};

export default RivePlayPauseButton;